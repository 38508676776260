<template>
  <div>
    <el-dialog
      title="考试成绩"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="450px"
    >
      <table-list
        class="tableList"
        :data="list"
        :height="250"
        :columns="columns(this)"
      ></table-list>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { examList } from '@/api/certificate'
import to from 'await-to'
import TableList from '@/components/TableList'
// 表格列配置
const columns = () => [
  {
    prop: 'subjectName',
    label: '科目',
  },
  {
    prop: 'score',
    label: '成绩',
  },
]
export default {
  components: {
    TableList,
  },
  data() {
    return {
      dialogVisible: false,
      columns,
      list: [],
    }
  },
  created() {},
  methods: {
    show(id) {
      this.dialogVisible = true
      this.examListData(id)
    },
    async examListData(id) {
      const [res, err] = await to(examList({ userCertSignRecordId: id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.list = res.data
    },
  },
}
</script>

<style scoped></style>
