<template>
  <div>
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="450px"
    >
      <el-form ref="form" :model="form" label-width="25px" class="form">
        <el-form-item>
          <upload-file
            ref="uploadFile"
            v-model="form.list"
            :file-type="'.pdf'"
            :limit="1"
          ></upload-file>
          <p class="uploadFileText">单个文件大小5MB，支持扩展名.pdf, 仅支持上传一个文件</p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import to from 'await-to'
import { createExamCard, lookExamCard, editExamCard } from '@/api/certificate'
import UploadFile from '@/components/UploadFile'
export default {
  components: {
    UploadFile,
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        list: [],
      },
      title: '',
      id: '',
    }
  },
  created() {},
  methods: {
    show(name, id) {
      this.id = id
      this.dialogVisible = true
      this.form.list = []
      if (name == 'upload') {
        this.title = '上传准考证'
      } else {
        this.title = '查看准考证'
        this.lookExamCardData(id)
      }
    },
    submit() {
      console.log(this.form)
      var ext = this.form.list[0].name.split('.')[1]
      let obj = {
        ext: ext,
        fileName: this.form.list[0].name,
        fileUrl: this.form.list[0].url,
        userCertSignRecordId: this.id,
      }
      if (this.title == '上传准考证') {
        this.createdUpload(obj)
        this.$emit('success')
      } else {
        this.editExamCard(obj)
      }
    },
    async createdUpload(obj) {
      const [, err] = await to(createExamCard(obj))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '上传成功' })
      this.dialogVisible = false
    },
    async editExamCard(obj) {
      const [, err] = await to(editExamCard(obj))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '成功' })
      this.dialogVisible = false
    },
    async lookExamCardData(id) {
      const [res, err] = await to(lookExamCard({ userCertSignRecordId: id }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.form.list.push({
        name: res.data.fileName,
        url: res.data.fileUrl,
        docName: res.data.fileName,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.uploadFileText {
  color: #909399;
  font-size: 12px;
}
</style>
