<template>
  <div>
    <table-list
      class="tableList"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="signUpList"
      :title="tableTitle"
      :tabs-list="tabsList"
      :options="{ selection: true, operatesWidth: 250 }"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <TestCard ref="TestCard" @success="signUpListData()" />
    <AchievementDia ref="AchievementDia" />
  </div>
</template>

<script>
//表格tabs
const tabsList = [
  {
    prop: 'signStatus',
    activeValue: '',
    children: [
      { value: 2, label: '提交资料' },
      { value: 3, label: '审核资料' },
      { value: 4, label: '培训中' },
      { value: 5, label: '已完成' },
    ],
  },
  {
    prop: 'productAttributeCategoryId',
    activeValue: '',
    children: [
      { value: 3, label: '职业等级证书' },
      { value: 2, label: '专项职业能力证书' },
    ],
  },
]
//表格标题
const tableTitle = '报名考证信息'
const btns = _this => [
  {
    label: '导入与导出',
    method: _this.handleImOrEx,
  },
  {
    label: '导出报名资料',
    method: _this.handleExport,
  },
  {
    label: '导出报名列表',
    method: _this.handleExportList,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '请输入姓名',
    prop: 'signUserName',
  },
  {
    label: '请输入手机号',
    prop: 'userPhone',
  },
  {
    label: '请选择证书',
    prop: 'certId',
    type: 'select',
    filterable: true,
    children: _this.selectList.map(item => ({
      value: item.certId,
      label: item.certName,
    })),
  },
  {
    label: '创建时间',
    prop: 'time',
    type: 'picker',
  },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'userPhone',
    label: '手机号',
    minWidth: '120px',
  },
  {
    prop: 'signUserName',
    label: '报名人',
    minWidth: '100px',
  },
  {
    prop: 'productAttributeCategoryName',
    label: '报名证书',
    minWidth: '160px',
  },
  {
    prop: 'productName',
    label: '报名商品名称',
    minWidth: '160px',
  },
  {
    prop: 'skuName',
    label: '所选服务',
    minWidth: '150px',
    twoLines: true,
  },
  {
    prop: 'owner',
    label: '归属人',
    minWidth: '100px',
  },
  {
    prop: 'userCertCode',
    label: '成绩',
    minWidth: '80px',
    // align: 'right',
    render: (h, { row }) => {
      return row.hasAchievement == 1
        ? h('div', [
            h(
              'div',
              {
                style: {
                  cursor: 'pointer',
                  color: '#409EFF',
                },
                on: {
                  click: function() {
                    _this.$refs.AchievementDia.show(row.userCertSignRecordId)
                  },
                },
              },
              '查看',
            ),
          ])
        : ''
    },
  },
  {
    prop: 'signStatus',
    label: '当前状态',
    minWidth: '120px',
    formatter: row => {
      if (row.signStatus == 2) {
        return '提交资料'
      } else if (row.signStatus == 3) {
        return '审核资料'
      } else if (row.signStatus == 4) {
        return '培训中'
      } else if (row.signStatus == 5) {
        return '已完成'
      }
    },
  },
  {
    prop: 'className',
    label: '所在培训计划',
    minWidth: '120px',
  },
  {
    prop: 'examTime',
    label: '考试时间',
    minWidth: '150px',
  },
  {
    prop: 'createTime',
    label: '创建时间',
    minWidth: '150px',
    sortdata: 1,
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '报名资料',
    method: _this.handleDetail,
  },
  {
    label: '上传准考证',
    method: _this.handleUpload,
    isShow: row => row.signStatus == 4 && row.hasAdmissionTicket == 2,
  },
  {
    label: '查看准考证',
    method: _this.handleLook,
    isShow: row => row.signStatus == 4 && row.hasAdmissionTicket == 1,
  },
]
import TestCard from './components/TestCard.vue'
import AchievementDia from './components/AchievementDia.vue'
import TableList from '@/components/TableList'
import {
  signUpList,
  certificateList,
  signUpDataExport,
  signUpRecordExport,
} from '@/api/certificate'
import to from 'await-to'
export default {
  components: {
    TableList,
    TestCard,
    AchievementDia,
  },
  data() {
    return {
      tableTitle,
      btns,
      searchForm,
      columns,
      operates,
      tabsList,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      certificatePager: {
        size: 1000,
        current: 1,
      },
      queryParams: {},
      select: [],
      selectList: [],
      signUpList: [],
    }
  },
  created() {
    this.signUpListData()
    this.certificateListData()
  },
  methods: {
    async signUpListData() {
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(signUpList({ current, size, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.signUpList = res.data.list
      this.pager.total = res.data.total
    },
    async certificateListData() {
      const { current, size } = this.certificatePager
      const [res, err] = await to(certificateList({ current, size, reviewStatus: 1 }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.selectList = res.data.list
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      if (queryParams.time) {
        queryParams.createTimeStart = queryParams.time[0]
        queryParams.createTimeEnd = queryParams.time[1].split(' ')[0] + ' 23:59:59'
      } else {
        queryParams.createTimeStart = ''
        queryParams.createTimeEnd = ''
      }
      this.queryParams = queryParams
      this.signUpListData()
    },
    handleSelectionChange(val) {
      this.select = val
    },
    handleImOrEx() {
      this.$router.push('/certificate/importOrExport')
    },
    async handleExport() {
      if (this.select.length == 0) return this.$message.warning('请您选择要导出资料的报名人')
      let userCertSignRecordIds = []
      this.select.map(v => userCertSignRecordIds.push(v.userCertSignRecordId))
      const { size, current } = this.pager
      const [res, err] = await to(
        signUpRecordExport({
          size,
          current,
          ...this.queryParams,
          userCertSignRecordIds: userCertSignRecordIds,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/zip',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      // a.download = '报名资料列表.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    async handleExportList() {
      let userCertSignRecordIds = []
      this.select.map(v => userCertSignRecordIds.push(v.userCertSignRecordId))
      const [res, err] = await to(
        signUpDataExport({ userCertSignRecordIds: userCertSignRecordIds }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '报名列表.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    handleDetail(row) {
      this.$router.push({
        path: '/certificate/data',
        query: {
          userCertSignRecordId: row.userCertSignRecordId,
        },
      })
    },
    handleUpload(row) {
      console.log(row)
      this.$refs.TestCard.show('upload', row.userCertSignRecordId)
    },
    handleLook(row) {
      this.$refs.TestCard.show('look', row.userCertSignRecordId)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.signUpListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.signUpListData()
    },
  },
}
</script>

<style scoped lang="scss"></style>
